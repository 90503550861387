import PropTypes from "prop-types"
import React from "react"
import { FormattedMessage, FormattedNumber } from "gatsby-plugin-react-intl"

import Description from "../../Description"

const statistics = [
  { id: "heartattack", icon: "people" },
  { id: "aphasia", icon: "symptoms" },
  { id: "risk", icon: "calendar" },
  { id: "period", icon: "repeat" },
]

const Statistics = ({ intl, rules, styles }) => (
  <Description
    title={<FormattedMessage id={`aphasia.statistics.title`} />}
    extend={{ title: rules.title }}
    align="center"
  >
    <div className={styles.statistics}>
      {statistics.map(({ id, icon }) => (
        <Description
          key={id}
          title={
            <FormattedNumber
              value={intl.formatMessage({
                id: `aphasia.statistics.${id}.value`,
              })}
            />
          }
          icon={icon}
          size={Description.Size.TINY}
          align="center"
        >
          <FormattedMessage id={`aphasia.statistics.${id}`} tagName="p" />
        </Description>
      ))}
    </div>
  </Description>
)

Statistics.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
  styles: PropTypes.shape({
    statistics: PropTypes.string.isRequired,
  }).isRequired,
}

export default Statistics
