import PropTypes from "prop-types"
import React from "react"
import LazyLoad from "react-lazyload"

import { FormattedMessage } from "gatsby-plugin-react-intl"

import { Heading2 } from "../Heading"
import Section from "../Section"
import Description from "../Description"
import Statistics from "./Statistics"

import { subSectionExtend } from "./Aphasia.styles"
import LineSeparator from "../LineSeparator"

const Aphasia = ({ styles, rules }) => (
  <Section>
    <FormattedMessage id="aphasia.title">
      {title => <Heading2 customStyle={rules.title}>{title}</Heading2>}
    </FormattedMessage>

    <LazyLoad height={200}>
      <FormattedMessage id="aphasia.description">
        {description => (
          <p
            className={styles.description}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </FormattedMessage>
      <LineSeparator customStyle={rules.separator} />

      <div className={styles.subSections}>
        {["symptoms", "therapy"].map(id => (
          <Description
            key={id}
            title={<FormattedMessage id={`aphasia.${id}.title`} />}
            icon={id}
            extend={subSectionExtend}
          >
            <FormattedMessage id={`aphasia.${id}.description`}>
              {description => (
                <p dangerouslySetInnerHTML={{ __html: description }} />
              )}
            </FormattedMessage>
          </Description>
        ))}
      </div>

      <LineSeparator customStyle={rules.separator} />

      <Statistics />
    </LazyLoad>
  </Section>
)

Aphasia.propTypes = {
  styles: PropTypes.shape({
    description: PropTypes.string.isRequired,
  }).isRequired,
}

export default Aphasia
