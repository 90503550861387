export const title = () => ({
  marginTop: "3.5rem",
})

export const description = ({ theme }) => ({
  mdMin: {
    padding: "0 13rem",
  },
})

export const separator = () => ({
  marginBottom: "5rem",
})

export const subSections = () => ({
  display: "flex",
  marginBottom: "4.7rem",

  smMax: {
    flexDirection: "column",
  },
})

export const subSectionExtend = () => ({
  description: {
    smMin: {
      ":first-child": {
        marginRight: "5rem",
      },
      ":last-child": {
        marginLeft: "5rem",
      },
    },
  },
})
