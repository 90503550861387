import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

import { anchorItems } from "../constants"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import AphasiaIntro from "../components/AphasiaIntro"
import Contact from "../components/Contact"
import Aphasia from "../components/Aphasia"
import CtaRow from "../components/CtaRow"
import Button from "../components/Button"

const main = ({ theme: { videoOverflow } }) => ({
  marginTop: `${videoOverflow.default}rem`,

  smMin: {
    marginTop: `${videoOverflow.sm}rem`,
  },

  lgMin: {
    marginTop: `${videoOverflow.lg}rem`,
  },
})

const AphasiaPage = () => {
  const intl = useIntl()

  return (
    <Layout headerIntro={<AphasiaIntro />} extend={{ main }}>
      <Seo title={intl.formatMessage({ id: "page.aphasia.title" })} />

      <Aphasia />

      <CtaRow
        titleId="aphasia.cta.title"
        buttonLabelId="cta.more"
        link={{
          to: `/#${anchorItems.about}`,
          type: Button.Types.INTERNAL_LINK,
        }}
      />
      <Contact />
    </Layout>
  )
}
export default AphasiaPage
