export const title = () => ({
  marginTop: "4.3rem",
  marginBottom: "3.3rem",
})

export const statistics = () => ({
  display: "flex",
  marginTop: "2rem",

  smMax: {
    flexDirection: "column",
  },
})
