export const intro = () => ({
  marginBottom: 0,

  smMin: {
    marginBottom: 0,
  },

  mdMin: {
    marginBottom: 0,
  },
})

export const video = ({ theme: { videoOverflow, headerBottomPadding } }) => ({
  marginBottom: `-${videoOverflow.default + headerBottomPadding}rem`,

  smMin: {
    marginBottom: `-${videoOverflow.sm + headerBottomPadding}rem`,
  },

  lgMin: {
    marginBottom: `-${videoOverflow.lg + headerBottomPadding}rem`,
  },
})
