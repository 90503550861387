import PropTypes from "prop-types"
import React from "react"

const Video = ({ url, styles }) => (
  <div className={styles.container}>
    <div className={styles.videoSizer}>
      <div className={styles.videoRatioHolder}>
        <iframe
          width="100%"
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Video"
        ></iframe>
      </div>
    </div>
  </div>
)

Video.propTypes = {
  styles: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default Video
