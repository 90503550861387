import React from "react"

import BasicIntro from "../BasicIntro"
import Video from "../Video"

const AphasiaIntro = ({ rules }) => (
  <BasicIntro intlId="aphasia" extend={{ intro: rules.intro }}>
    <Video
      url="https://www.youtube.com/embed/h8ivqZDxMTU?rel=0&amp;controls=0"
      extend={{ container: rules.video }}
    />
  </BasicIntro>
)

export default AphasiaIntro
