export const videoSizer = ({ theme }) => ({
  maxWidth: theme.widths.content,
  margin: "0 auto",
})

export const videoRatioHolder = () => ({
  height: "0",
  paddingBottom: "56.25%",
  position: "relative",

  "& iframe": {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
})

export const container = ({ theme }) => ({
  backgroundColor: theme.colors.blueLight3,

  "& iframe": {
    display: "block",
    margin: "0 auto",
  },
})
